import React from 'react'
import Gdpr from './../components/Gdpr'
import {Helmet} from 'react-helmet'
import './../styles/index.scss'

const Layout = ({children}) => 
    <React.Fragment>
        <Helmet>
            <html lang="fr" />
            <meta charSet="utf-8" />
            <title>Smile All Day &amp; Night</title>
            <meta property="fb:app_id" content="274093503648652"/>
            <meta property="og:local" content="fr_FR"/>
            <meta property="og:site_name" content="smilealldayandnight.fr"/>
        </Helmet>
        {children}
        <Gdpr/>
    </React.Fragment>

export default Layout;

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const trackable = require('./src/trackable').default;

exports.onClientEntry = (e) =>
{
    trackable();
}

exports.onRouteUpdate = (e) =>
{

    setTimeout(() => 
    {

        window.gtag('event', 'page_view' , 
        {
            'page_title': document.title,
            'page_location': document.location.href,
            'page_path': document.location.pathname
        });

    } , 32 )

}
export default function ()
{

    document.addEventListener('click',(e) => 
    {

        let c = e.target as Element | null;
        while (c && c.tagName !== 'A')
        {
            c = c.parentElement;
        }
        if (c === null)
            return;

        if (!c.hasAttribute('data-trackable'))
            return;
    
        const trackable = c.getAttribute('data-trackable');
        if (trackable != "" && trackable != "1")
        {
            return;
        }
    
        const a = c as HTMLAnchorElement;

        if (a.hasAttribute('data-share-network'))
        {
            return shareWith(e,a.getAttribute('data-share-network'));
        }
    
        if (!a.hasAttribute('href') || a.getAttribute('href') == "")
        {
            return;
        }

        const w = window as any;
        const blank = a.getAttribute('target') === '_blank';
        const u = a.href;
        const opts : any = 
        {
            event_label: u,
            event_category: 'Outbound',
            page_title: document.title,
            page_location: document.location.href,
            page_path: document.location.pathname
        }
    
        if (!blank)
        {
            e.preventDefault();
            opts.transport_type = 'beacon'
            opts.event_callback = () => document.location.href = u;
        }
    
        w.gtag('event', "Click", opts);
    
        if (!blank)
        {
            return false;
        }

    },false)

}

function shareWith(e,network)
{

    network = network[0].toUpperCase() + network.substr(1).toLowerCase();
    let android = navigator.userAgent.match(/Android/i);
    let ios = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    let mac = navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i);
    const isDesktop = !(ios || android);

    const l = document.location;
    const u = encodeURIComponent(l.protocol + '//' + l.host + l.pathname);
    const w = window as any;
    const t = encodeURIComponent('Gardez le sourire jour et nuit.');
    e.preventDefault();
    
    if (network == 'Messenger')
    {

        const m = document.querySelector('meta[property="fb:app_id"]');
        const i = m ? m.getAttribute('content') : '966242223397117';
        if (isDesktop)
        {
            const params = 
            {
                'app_id': i,
                'display':'popup',
                'href': u,
                'link': u,
                'redirect_uri': u,
                'quote': t                
            }
            const q : string[] = [];
            for(let k in params)
            {
                q.push(k + '=' + params[k]);
            }
            window.open('http://www.facebook.com/dialog/send?' + q.join('&'));
        }
        else
        {
            window.open('fb-messenger://share/?app_id=' + i + '&link='+u+'&message='+t)
        }
   
    }

    if (network == 'Facebook')
    {

        window.open('https://www.facebook.com/sharer/sharer.php?u=' + u)
   
    }

    if(network == 'Whatsapp')
    {
        window.open((isDesktop ? 'https://api.whatsapp.com/send?text=' : 'whatsapp://send?text=') + t + encodeURIComponent("\n\r") + u);
    }
    
    w.gtag('event', "Click", 
    {
        event_label: network,
        event_category: 'Share',
        page_title: document.title,
        page_location: document.location.href,
        page_path: document.location.pathname
    });

    return false;

}
import React from 'react'
import { func } from 'prop-types';

const EXPIRES = 30 * 24 * 60 * 60 * 1000
const KEY     = '__hoshy-consent__'

function currentUserHasBeenNotNotified() : boolean
{

     return document.cookie.match(new RegExp(KEY + '=(0|1)')) == null;
    
}

function currentUserSetConsent(x:boolean)
{

    if (x)
    {

        const date = new Date()
        date.setTime( date.getTime() + EXPIRES )
        document.cookie = KEY + '=1; expires=' + date.toUTCString() + '; path=/';

    }

    else
    {

        window['ga-disable-UA-156984796-14'] = true;
        document.cookie = "_ga= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/"
        document.cookie = "_gid= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/"
        document.cookie = "_gat_gtag_UA_156984796_14= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/"
        document.cookie = KEY + '=0; path=/';

    }

}

function onClickOkButton(e: any)
{

    e.preventDefault()
    currentUserSetConsent(true)
    document.getElementById('GdprPopup')!.classList.remove('s--show')
    return false

}

function onClickNotOkButton(e:any)
{
    e.preventDefault()
    currentUserSetConsent(false)
    document.getElementById('GdprPopup')!.classList.remove('s--show')
    return false
}

const Popup = () =>
{

    typeof window !== 'undefined' && 
    currentUserHasBeenNotNotified() && 
    setTimeout(() => 
    {
    
        document.getElementById('GdprPopup')!.classList.add('s--show')
    
    } , 2000)

    const out = 
        <div id="GdprPopup" className="Gdpr-Wrapper x--br x--v">
            <div className="Gdpr-Msg">
                En poursuivant votre navigation sur ce site, 
                vous acceptez l’utilisation de Cookies pour 
                réaliser des statistiques de visites.<br/>
                <a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi" target="_blank" rel="noopener">
                    En savoir +
                </a>
                <p className="Gdpr-ButtonsWrapper">
                    <a href="#" className="Gdpr-Button x--Ok" onClick={onClickOkButton}>
                        j'ai compris
                    </a>
                    <a href="#" className="Gdpr-Button x--NotOk" onClick={onClickNotOkButton}>
                        refuser
                    </a>
                </p>
            </div>
        </div>

    return out

}

export default Popup